import { useState } from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import {
    Container,
    Content,
    Description,
    GlobalStyle,
    WrapperPicture,
    SubContent,
    Text,
    Title,
    WrapperSocialMediaIcons,
    WrapperSocialMediaSVG,
} from '../styles'
import themes from '../themes'
import dataJSON from '../data.json'
import InlineSVG from 'react-inlinesvg'
import { useEffect } from 'react'

const HelmetSEO = (props) => {
    const { title, description, image } = props

    return (
        <Helmet>
            <meta charSet="utf8" />
            <meta httpEquiv="content-language" content="en-us" />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={`../images/${image}`} />

            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={`../images/${image}`} />

            <title>Emmanuel Bergmann</title>
        </Helmet>
    )
}

const IndexPage = ({ data }) => {
    const { title, description, image } = data.site.siteMetadata

    const [isDarkTheme, setIsDarkTheme] = useState()

    useEffect(() => {
        const matchesDarkTheme = window.matchMedia(
            '(prefers-color-scheme: dark)'
        ).matches

        setIsDarkTheme(matchesDarkTheme)

        window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', ({ matches }) =>
                setIsDarkTheme(matches)
            )
    }, [])

    return (
        <>
            <HelmetSEO title={title} description={description} image={image} />

            <ThemeProvider theme={isDarkTheme ? themes.dark : themes.light}>
                <GlobalStyle />

                <Container>
                    <Content>
                        <SubContent>
                            <WrapperPicture>
                                <StaticImage
                                    src="../images/picture.jpg"
                                    alt={`Photo of ${title}`}
                                />
                            </WrapperPicture>
                        </SubContent>

                        <SubContent>
                            <Title>{title}</Title>
                            <Description>{description}</Description>
                        </SubContent>

                        <SubContent>
                            <Text>find more about me</Text>
                            <WrapperSocialMediaIcons>
                                {dataJSON.socialMedia.map((socialMedia) => (
                                    <a
                                        href={socialMedia.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        aria-label={socialMedia.name}
                                        key={socialMedia.iconName}
                                    >
                                        <WrapperSocialMediaSVG>
                                            <InlineSVG
                                                src={
                                                    require(`../images/${socialMedia.iconName}`)
                                                        .default
                                                }
                                            />
                                        </WrapperSocialMediaSVG>
                                    </a>
                                ))}
                            </WrapperSocialMediaIcons>
                        </SubContent>
                    </Content>
                </Container>
            </ThemeProvider>
        </>
    )
}

export const query = graphql`
    query IndexPage {
        site {
            siteMetadata {
                title
                description
                image
            }
        }
    }
`

export default IndexPage
