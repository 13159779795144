import styled, { createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'

export const GlobalStyle = createGlobalStyle`
    ::-moz-selection {
        background: ${({ theme }) => theme.primaryBackgroundColor};
    }
    ::selection {
        background: ${({ theme }) => theme.primaryBackgroundColor};
    }

    body {
        background-color: ${({ theme }) => theme.primaryBackgroundColor};
        background-size: 20px 20px;
        background-image: ${({ theme }) =>
            `linear-gradient(to right, ${theme.primaryColor} 1px, transparent 1px), 
            linear-gradient(to bottom, ${theme.primaryColor} 1px, transparent 1px)`}; 
        margin: 0;
        padding: 0;
        font-family: 'Nunito', sans-serif;
    }
`

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    /* background-color: green; */
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Content = styled.main`
    width: 95%;
    max-width: 800px;
    height: 95%;
    max-height: 690px;
    background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 6px 4px 100px 10px
        ${({ theme }) => rgba(theme.boxShadowColor, 0.25)};
    border-radius: 10px;
    transition: 0.5s all;

    @media only screen and (min-width: 768px) {
        height: 440px;
        max-height: auto;
    }
`

export const SubContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    /* background-color: green; */
`

export const WrapperPicture = styled.div`
    width: 250px !important;
    height: 250px !important;
    box-shadow: ${({ theme }) =>
        `${theme.boxShadowPictureX} ${theme.boxShadowPictureY} ${
            theme.boxShadowPictureBlur
        } ${theme.boxShadowPictureSpread} ${rgba(
            theme.boxShadowColor,
            theme.boxShadowColorOpacity
        )}`};
    border-radius: 50%;

    img {
        width: 250px !important;
        height: 250px !important;
        border-radius: 50%;
    }

    @media only screen and (min-width: 768px) {
        margin-top: -100px;
    }
`

export const Title = styled.h1`
    color: ${({ theme }) => theme.primaryColor};
    margin: 0;
    font-size: 24px;
`

export const Description = styled.h2`
    color: ${({ theme }) => theme.primaryColor};
    margin: 0;
    font-weight: 400;
    font-size: 18px;
`

export const Text = styled.p`
    color: ${({ theme }) => theme.primaryColor};
    font-weight: bold;
    font-size: 18px;
    margin: 0;
`

export const WrapperSocialMediaIcons = styled.div`
    /* background-color: green; */
    width: 60%;
    margin-top: 27px;
`

export const WrapperSocialMediaSVG = styled.div`
    width: fit-content;
    display: inline-block;
    margin: 0 15px;

    svg path {
        fill: ${({ theme }) => theme.socialMediaIconBackgroundColor};
    }
`
