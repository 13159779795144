const themes = {
    light: {
        primaryBackgroundColor: '#D6F3F8',
        secondaryBackgroundColor: '#FFFFFF',
        primaryColor: '#000000',
        secondaryColor: '#486E74',
        boxShadowColor: '#000000',
        boxShadowColorOpacity: 0.25,
        boxShadowPictureX: '6px',
        boxShadowPictureY: '4px',
        boxShadowPictureBlur: '150px',
        boxShadowPictureSpread: '5px',
        socialMediaIconBackgroundColor: '#486E74',
    },
    dark: {
        primaryBackgroundColor: '#000000',
        secondaryBackgroundColor: '#141414',
        primaryColor: '#7C7C7C',
        secondaryColor: '#7C7C7C',
        boxShadowColor: '#CCCCCC',
        boxShadowColorOpacity: 0.25,
        boxShadowPictureX: '6px',
        boxShadowPictureY: '4px',
        boxShadowPictureBlur: '150px',
        boxShadowPictureSpread: '5px',
        socialMediaIconBackgroundColor: '#7C7C7C',
    },
}

export default themes
